import React from 'react';
import PromotionsContainer from '../../components/PromotionsContainer';

export default function PagePromotions() {
    return (
      <div>
        <PromotionsContainer/>
      </div>
  )
}
